//@ts-ignore
import { Lethargy } from 'lethargy';
import React, { FC, useState } from 'react';
import { useWindowSize } from 'react-use';
import { useWheel } from 'react-use-gesture';
import styled from 'styled-components';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import leftArrow from '../resources/leftArrow.svg';
import rightArrow from '../resources/rightArrow.svg';

const lethargy = new Lethargy();

interface Props {
  isMenuOpen: boolean;
  pictures: any[];
  closeMenu: Function;
}

let timeoutStop = true;
let timeout: any = undefined;

const Slider: FC<Props> = ( { isMenuOpen, pictures, closeMenu } ) => {

  const [ controlledSwiper, setControlledSwiper ] = useState<SwiperCore | null>( null );

  //@ts-ignore
  const next = () => {
    controlledSwiper?.slideNext();
    closeMenu()
  }
  const prev = () => {
    controlledSwiper?.slidePrev();
    closeMenu()
  }

  let { height, width } = useWindowSize();

  let element = width / height;
  element = element > 5 ? 5 : element;
  element = element < 1.2 ? 1.1 : element;

  //// listen wheel effect, and go next only when the menu is not open
  //useEffect( () => {
  //  const listen = ( event: any ) => {
  //    if ( !controlledSwiper || isMenuOpen ) {
  //      return;
  //    }
  //
  //    if ( !timeoutStop ) {
  //      return;
  //    }
  //
  //    timeoutStop = false;
  //    setTimeout( () => timeoutStop = true, 1000 );
  //
  //    const deltaY = Math.sign( event.deltaY );
  //    const deltaX = Math.sign( event.deltaX );
  //
  //    if ( deltaY === -1 ) {
  //      next();
  //    } else if ( deltaY === 1 ) {
  //      prev();
  //    }
  //    if ( deltaX === -1 ) {
  //      next();
  //    } else if ( deltaX === 1 ) {
  //      prev();
  //    }
  //  };
  //
  //  window.addEventListener( 'wheel', listen );
  //
  //  return () => {
  //    window.removeEventListener( 'wheel', listen );
  //  };
  //
  //}, [ next, prev ] );

  const bind = useWheel( ( { event, last, memo : wait = false } ) => {
    if ( !last ) {
      const s = lethargy.check( event );
      if ( s ) {
        if ( !wait && timeoutStop && !isMenuOpen ) {

          if ( timeout ) {
            clearTimeout( timeout );
          }

          timeoutStop = false;
          setTimeout( () => timeoutStop = true, 500 );

          // run the stuff
          s < 0 ? next() : prev();
          return true;
        }
      } else {
        return false;
      }
    } else {
      console.log( 'rested' );
      return false;
    }
  } );

  // keep if I need it
  //const spacing = element <= 2 ? 140 : element / 2 * 100;

  return (
    <MainSliderComponentStyled { ...bind() }>
      <Swiper
        onSwiper={ setControlledSwiper }
        spaceBetween={ 40 }
        slidesPerView={ element }
        centeredSlides={ true }
        //onSlideChange={ () => console.log( 'slide change' ) }
      >
        {
          pictures.map( ( pic, index ) =>
            <SwiperSlide key={ index }>
              <img src={ process.env.PUBLIC_URL + pic } alt=""/>
            </SwiperSlide> )
        }
      </Swiper>

      <div className="arrowContainer">
        <div className="arrowDiv">
          <img src={ leftArrow } alt="left arrow"
            onClick={ prev }
            className={ 'arrow arrow--left ' + 'disabled' }
          />

          <img src={ rightArrow } alt="right arrow"
            onClick={ next }
            className={ 'arrow arrow--right ' + 'disabled' }
          />
        </div>
      </div>
    </MainSliderComponentStyled>
  );
};

const controlSize = 80;
export const TOP_BAR_HEIGHT = 80;

const MainSliderComponentStyled = styled.div`
  position: relative;

  .swiper-container {
    height : calc(100vh - ${ controlSize }px - ${ TOP_BAR_HEIGHT }px);
  }

  .swiper-slide {
    display         : flex;
    flex-direction  : column;
    align-content   : center;
    justify-content : center;

    img {
      width      : 100%;
      object-fit : contain;
      height     : 100%;
    }
  }

  .arrowContainer {
    height : ${ controlSize }px;

    & > div {
      position  : fixed;
      bottom    : 0;
      left      : 50%;
      transform : translateX(-50%);
    }
  }

  .arrow {
    width     : 30px;
    height    : 30px;
    position  : absolute;
    bottom    : 0;
    transform : translateY(-50%);
    fill      : black;
    cursor    : pointer;
  }

  .arrow--left {
    right : 5px;
  }

  .arrow--right {
    left : 5px;
  }

  .arrow--disabled {
    fill : rgba(255, 255, 255, 0.5);
  }

`;

export default Slider;


