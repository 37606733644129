import 'normalize.css';
import React, { useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import SwitchTransition from 'react-transition-group/SwitchTransition';
import styled from 'styled-components';
import { ROUTES_LINKS } from '../setup.const';
import NavBar from './NavBar';
import Slider from './Slider';

function App() {
  let location = useLocation();
  const [ menuOpen, setMenuOpen ] = useState( false );

  if ( !location.key ) {
    location.key = 'start';
  }

  return (
    <AppStyled className="App">

      <NavBar menuOpen={ menuOpen } setMenuOpen={ setMenuOpen }/>

      <SwitchTransition mode="out-in">
        <CSSTransition key={ location.key } classNames="route" timeout={ 300 }>
          <Switch location={ location }>
            {
              ROUTES_LINKS.map( ( { link, files } ) =>
                <Route key={ link } path={ '/' + link } exact>
                  <Slider isMenuOpen={ menuOpen } pictures={ files } closeMenu={() => setMenuOpen(false)}/>
                </Route>
              )
            }
            <Route path="/" exact>
              <Redirect to="opera"/>
            </Route>
          </Switch>
        </CSSTransition>
      </SwitchTransition>

    </AppStyled>
  );
}

const AppStyled = styled.div`
  overflow : hidden;
  height   : 100vh;

  .route-enter {
    opacity   : 0;
    transform : translateX(20%);
  }

  .route-enter-active {
    opacity    : 1;
    transform  : translateX(0);
    transition : opacity 300ms, transform 300ms;
  }

  .route-exit {
    opacity : 1;
  }

  .route-exit-active {
    opacity    : 0;
    transform  : translateX(-50%);
    transition : opacity 300ms, transform 500ms;
  }

`;

export default App;
