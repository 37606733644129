import { gsap } from 'gsap';
import React, { createRef, FC, useLayoutEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../resources/logo.svg';
import { ROUTES_LINKS } from '../setup.const';
import { TOP_BAR_HEIGHT } from './Slider';

interface Props {
  menuOpen: boolean
  setMenuOpen: Function
}

const NavBar: FC<Props> = ( { menuOpen, setMenuOpen } ) => {
  const triggerMenu = () => setMenuOpen( !menuOpen );

  // Refs
  const revealTimeLine = useRef( gsap.timeline( { paused : true } ) );
  const hideTimeLine = useRef( gsap.timeline( { paused : true } ) );
  const refMenuElement = useRef( null );
  const refElements = useRef<React.RefObject<any>[]>( ROUTES_LINKS.map( () => createRef() ) );

  useLayoutEffect( () => {
    const element = refElements.current.map( ( { current } ) => current );

    revealTimeLine.current
                  .set(refMenuElement.current, {x: 0, display: 'hidden'})
                  .to( refMenuElement.current, { duration : 0.5, autoAlpha : 1 } )
                  .fromTo( element, { x : -500 }, { duration : .2, x : 0, delay : -0.5, stagger : 0.05 } );

    hideTimeLine.current
                .to( refMenuElement.current, { x : '-110%', duration : 0.5 } )
  }, [ revealTimeLine ] );

  useLayoutEffect( () => {
    if ( menuOpen ) {
      revealTimeLine.current.restart().play();
    } else {
      hideTimeLine.current.restart().play();
    }
  }, [ menuOpen, revealTimeLine ] );

  return (
    <TopBarStyled>
      <div className="logo" onClick={ triggerMenu }>
        <img src={ logo } alt="logo"/>
      </div>

      <div className="menuElement" ref={ refMenuElement }>
        {
          ROUTES_LINKS.map(
            ( el, index ) =>

              <NavLink
                ref={ refElements.current[ index ] }
                key={ index }
                activeClassName="active"
                to={ '/' + el.link }
                exact
                className={ index % 2 ? 'item-bold item' : 'item-monospace item' }
                onClick={ triggerMenu }>
                {
                  ( index === ROUTES_LINKS.length - 2 ) &&
                  <hr className="sep"/>
                }
                <p className={ el.twoLine ? 'twoLine' : undefined }>
                  { el.text }
                </p>
              </NavLink>
          )
        }
      </div>
    </TopBarStyled>
  );
};

const TopBarStyled = styled.div`
  height   : ${ TOP_BAR_HEIGHT }px;
  position : relative;

  .sep {
    width       : 20%;
    margin-left : 25px;
    margin-top  : 20px;
    margin-bottom  20px;
  }

  // link
  a {
    margin         : 0;
    display        : block;
    color          : black;
    font-family    : news-701, serif;
    font-weight    : 300;
    font-style     : italic;
    letter-spacing : 2px;
    white-space    : pre;
    cursor         : pointer;

    :nth-child(odd) {
      font-family : Overpass, sans-serif;
      font-style  : normal;
      margin-top  : 0px;
      font-weight : 800;
    }

    &.active p {
      color : red;
    }

    p {
      font-size : 4vw;
      padding   : 0px 40px 8px 24px;

      @media (min-width : 638px) {
        font-size : 35px;
        padding   : 0px 40px 8px 24px;

      }
      margin    : 0;
    }
  }

  .menuElement {
    visibility  : hidden;
    overflow    : auto;
    height      : 100vh;
    width       : 100%;
    padding-top : 90px;
    position    : absolute;
    left        : 0;
    top         : 0;
    background  : white;
    z-index     : 100;

    @media (min-width : 638px) {
      font-size : 35px;
      background  : none;
    }
  }

  .logo {
    cursor   : pointer;
    position : absolute;
    left     : 20px;
    top      : 10px;
    height   : 60px;
    width    : 60px;
    z-index  : 1000;
  }
`;

export default NavBar;

