function importAll( r: any ) {
  return r.keys().map( r );
}

export const ROUTES_LINKS = [
  {
    link  : 'opera', text : 'OPÉRA DE MONTE-CARLO',
    //@ts-ignore
    files : importAll(require.context( './resources/' + '', true, /\.jpg$/ ))
  },
  {
    link  : 'fontanais', text : 'FONTALBAT-MAZARS',
    //@ts-ignore
    files : importAll(require.context( './resources/' + '', true, /\.jpg$/ ))
  },
  {
    link  : 'culture', text : 'CULTURESPACES',
    //@ts-ignore
    files : importAll(require.context( './resources/' + 'culturespace', true, /\.jpg$|.gif$/ ))
  },
  {
    link  : 'soleil', text : '1.9.3.  SOLEIL !',
    //@ts-ignore
    files : importAll(require.context( './resources/' + 'soleil', true, /\.jpg$/ ))
  },
  {
    link  : 'jean', text : 'JEANTET',
    //@ts-ignore
    files : importAll(require.context( './resources/' + 'jeantet', true, /\.jpg$/ ))
  },
  {
    link  : 'orange', text : 'CHORÉGIES D’ORANGE',
    //@ts-ignore
    files : importAll(require.context( './resources/' + 'choregies', true, /\.jpg$/ ))
  },
  {
    link  : 'thonon', text : 'THONON-LES-BAINS',
    //@ts-ignore
    files : importAll(require.context( './resources/' + 'thonon', true, /\.jpg$/ ))
  },
  {
    link  : 'marionnettes', text : 'FESTIVAL MARIONNETTES',
    //@ts-ignore
    files : importAll(require.context( './resources/' + 'fmtm', true, /\.jpg$|.gif$/ ))
  },
  {
    link  : 'favarger', text : 'FAVARGER',
    //@ts-ignore
    files : importAll(require.context( './resources/' + 'favarger', true, /\.jpg$/ ))
  },
  {
    link  : 'art', text : 'ART PARIS',
    //@ts-ignore
    files : importAll(require.context( './resources/' + 'artparis', true, /\.jpg$/ ))
  },
  {
    link  : 'institut', text : 'INSTITUT DU CERVEAU\nET DE LA MOELLE ÉPINIÈRE',
    twoLine : true,
    //@ts-ignore
    files : importAll(require.context( './resources/' + 'icm', true, /\.jpg$/ ))
  },
  {
    link  : 'identity', text : 'IDENTITÉS',
    //@ts-ignore
    files : importAll(require.context( './resources/' + '', true, /\.jpg$/ ))
  },
  {
    link  : 'edition', text : 'ÉDITION',
    //@ts-ignore
    files : importAll(require.context( './resources/' + 'edition', true, /\.jpg$/ ))
  }
];
